#root {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	background-color: #202020;
	display: flex;
}

:root {
	--accent: #202020;
}

.random-spa-logo {
	font-size: 90px;
	color: #7ed857;
	font-weight: bold;
	position: absolute;
	top: 50px;
	left: 50px;
	cursor: pointer;
}

.random-spa-flex {
	width: 650px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex: 1;
}

.random-spa-container {
	width: 50%;
	height: 100%;
	box-sizing: border-box;

	background: linear-gradient(0deg, var(--accent) 0%, rgba(0, 0, 0, 1) 100%);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding-right: 100px;

	transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
}

.random-spa-header-text {
	display: flex;
	flex-direction: column;
}

.random-spa-title {
	font-family: 'League Spartan', sans-serif;
	font-size: 155px;
	font-weight: bold;
	color: #7ed857;
	line-height: 0.8;
}

.random-spa-subtitle {
	font-family: 'League Spartan', sans-serif;
	font-size: 71px;
	font-weight: bold;
	color: #7ed857;
	line-height: 0.5;
}

.random-spa-minititle {
	font-family: 'League Spartan', sans-serif;
	font-size: 50px;
	color: #7ed857;
	position: absolute;
	left: 25px;
}

.random-spa-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;

	max-width: 650px;
}

.no-main {
	width: 95% !important;
	padding-right: 0;
	padding-left: 5%;
}

.return-button {
	font-size: 50px;
	font-weight: bold;
	user-select: none;

	position: absolute;
	left: 30px;
	top: 15px;

	cursor: pointer;
}

.random-spa-block-text {
	font-family: 'League Spartan', serif;
	font-size: 65px;
	color: #7ed857;
	font-weight: 600;
	letter-spacing: 1.2px;
	margin: 0 auto;
	width: 65%;
	margin-top: 50px;
	text-align: center;
	line-height: 1.2;
	opacity: 0;

	animation: appear 0.6s 0.5s linear forwards;
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.random-spa-body {
	width: 50%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #7ed857;
	position: relative;

	transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
}

.random-spa-loading {
	min-height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
}

.random-spa-loading-icon {
	font-size: 200px;
	color: #000;
	font-weight: 300;

	-webkit-animation: loading 1.6s linear infinite;
	-moz-animation: loading 1.6s linear infinite;
	-ms-animation: loading 1.6s linear infinite;
	-o-animation: loading 1.6s linear infinite;
	animation: loading 1.6s linear infinite;
}

@-webkit-keyframes loading

/* Safari and Chrome */
	{
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loading {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.random-spa-loading-text {
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 35px;
	color: #000;
	font-weight: 600;
	letter-spacing: 10px;
}

.random-spa-input {
	width: 100%;
	padding: 20px 35px;
	border-radius: 50px;
	background-color: #303030;
	border: none;

	font-family: 'League Spartan', sans-serif;
	font-size: 28px;
	letter-spacing: 2px;
	color: #fff;
}

.random-spa-input::placeholder {
	color: #fff;
	opacity: 0.6;
}

input:focus {
	outline: none;
}

.input-container {
	width: 100%;
	margin: 20px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.input-control {
	width: 100%;
	display: flex;
	position: relative;
}

.input-control-clear {
	font-size: 35px;
	font-weight: bold;
	color: #7ed857;
	line-height: 0.6;

	position: absolute;
	top: calc(50% - 10px);
	right: 25px;
	cursor: pointer !important;
	user-select: none;
}

.input-control-search {
	font-size: 40px;
	font-weight: bold;
	color: #7ed857;
	line-height: 0.6;

	position: absolute;
	top: calc(50% - 12px);
	right: 25px;
	cursor: pointer !important;
	user-select: none;
}

.random-spa-header-button {
	padding: 20px 25px;
	border-radius: 50px;
	background-color: #7ed857;

	font-family: 'League Spartan', sans-serif;
	font-size: 35px;
	font-weight: bold;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	user-select: none;
}

.random-spa-result-recents {
	width: 55%;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	font-family: 'League Spartan', sans-serif;
	color: #fff;
	font-size: 20px;
	gap: 15px;
}

.random-spa-artists-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	box-sizing: border-box;
}

.random-spa-artist-best-result {
	background: 3px solid #0cc205;
}

.random-spa-artist-container {
	display: flex;
	align-items: center;
	gap: 30px;
	padding: 13px 150px;
	cursor: pointer;
	box-sizing: border-box;

	transition: opacity 0.25s linear, filter 0.25s linear;
}

.random-spa-artist-container:first-child {
	border-bottom: 1px solid #fff;
}

/* hovered option */
.random-spa-artist-container:hover {
	background: rgb(255, 255, 255);
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0) 100%);
}

/* options not hoevered */
.random-spa-artist-container:not(:hover) {
	opacity: 0.6;
	filter: grayscale(100%);
}

.random-spa-artist-container img {
	width: 135px;
	height: 135px;
	object-fit: cover;
	border-radius: 100%;

	transition: width 0.2s linear, height 0.25s linear;
}

.default-no-image {
	width: 135px;
	height: 135px;
	background-color: #202020;
	border-radius: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 50px;
	color: #fff;
	line-height: 0.5;
}

.random-spa-artist-name {
	font-family: 'Kumbh Sans', sans-serif;
	color: #000;
	font-size: 40px;
	font-weight: 500;
	letter-spacing: 1.5px;

	transition: font-size 0.25s linear;
}

.random-spa-albums-container {
	display: flex;
	flex-wrap: wrap;
	width: 90%;
	justify-content: center;
	align-items: flex-start;
	overflow-y: scroll;
	height: 90%;
	column-gap: 20px;
	row-gap: 35px;
	box-sizing: border-box;
}

.random-spa-albums-container::-webkit-scrollbar {
	width: 15px;
}

.random-spa-albums-container::-webkit-scrollbar-track {
	background: transparent;
}

.random-spa-albums-container::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.5);
}

.random-spa-album-container {
	width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	opacity: 0.6;

	transition: opacity 0.3s linear;
}

.random-spa-album-container:hover {
	opacity: 1;
}

.random-spa-album-container img {
	width: 200px;
	height: 200px;
	border-radius: 5px;
}

.random-spa-album-container:hover img {
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.random-spa-album-name {
	font-family: 'Kumbh Sans', sans-serif;
	color: #000;
	font-size: 25px;
	font-weight: 500;
	letter-spacing: 1.5px;
	text-align: center;
	margin-top: 10px;

	transition: font-weight 0.3s linear;
}

.random-spa-result-name {
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Kumbh Sans', sans-serif;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1.5px;
	padding: 11px 20px;
	background-color: #303030;
	border-radius: 50px;
}

.random-spa-album {
	width: 500px;
	height: 500px;
	border-radius: 5px;
}

.random-spa-result-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	cursor: pointer;
}

.random-spa-result-icon {
	width: 20px;
	height: 22px;
	font-size: 20px;
	font-weight: bold;
	margin-left: 5px;
	user-select: none;
}

.random-spa-results {
	width: 820px;
	margin: 50px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 35px;
}

.random-spa-results-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	gap: 15px;
}

.random-spa-results-track {
	font-family: 'Kumbh Sans', sans-serif;
	color: #000;
	font-size: 50px;
	font-weight: bold;
	letter-spacing: 1.2px;
	text-align: center;
}

.random-spa-results-album {
	font-family: 'Kumbh Sans', sans-serif;
	color: #000;
	font-size: 45px;
	font-weight: 500;
	letter-spacing: 1.2px;
	text-align: center;
}

.random-spa-results-artist {
	font-family: 'Kumbh Sans', sans-serif;
	color: #000;
	font-size: 35px;
	font-weight: 500;
	letter-spacing: 1.2px;
	text-align: center;
	text-transform: uppercase;
}

.random-spa-nav {
	display: flex;
	position: absolute;
	bottom: 100px;
	left: 100px;
	gap: 20px;
}

.random-spa-nav div {
	font-family: 'League Spartan', sans-serif;
	color: #7ed857;
	font-size: 30px;
	font-weight: bold;
	letter-spacing: 1.2px;
	cursor: pointer;
}

.random-spa-buttons-container {
	display: flex;
	gap: 30px;
}

.random-spa-button {
	padding: 20px 25px;
	background: #00000030;
	border-radius: 55px;
	margin-top: 15px;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;

	cursor: pointer;
	user-select: none;
}

.random-spa-button .icon {
	font-size: 31px;
	line-height: 1;
}

.random-spa-button-text {
	font-family: 'Kumbh Sans', sans-serif;
	color: #000;
	font-size: 30px;
	letter-spacing: 1.2px;
	line-height: 1;
}

.disabled {
	opacity: 0.3;
	cursor: auto;
	color: #fff;
}

.disabled .random-spa-button-text {
	color: #fff
}

.random-spa-feedback {
	max-width: 450px;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;

	padding: 20px 25px;
	background-color: rgba(0, 0, 0, 0.9);
	border-radius: 20px;

	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	bottom: 50px;
	z-index: 99999 !important;
	user-select: none;
}

.random-spa-feedback-text {
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 20px;
	font-weight: 600;
	color: #fff;
	letter-spacing: 1.5px;
	text-align: center;
}

.random-spa-feedback .icon {
	font-size: 30px;
	cursor: pointer;
	color: #fff;
}

.icon {
	font-family: 'Material Symbols Outlined';
}

@media screen and (max-width: 1650px) {
	.random-spa-container {
		padding-right: 50px;
	}

	.random-spa-title {
		font-size: 110px;
	}

	.random-spa-subtitle {
		font-size: 50px;
	}

	.random-spa-flex {
		width: 460px;
	}

	.random-spa-header-button {
		font-size: 30px;
	}

	.random-spa-nav {
		bottom: 70px;
	}

	.random-spa-nav div {
		font-size: 25px;
	}

	.random-spa-album {
		width: 365px;
		height: 365px;
	}

	.random-spa-results-track {
		font-size: 35px;
	}

	.random-spa-results-album,
	.random-spa-artist-name {
		font-size: 30px;
	}

	.random-spa-results-artist,
	.random-spa-button-text {
		font-size: 25px;
	}

	.random-spa-button-text {
		margin: 0;
	}

	.random-spa-artist-container img {
		width: 105px;
		height: 105px;
	}

	.random-spa-album-container {
		width: 180px;
	}

	.random-spa-album-container img {
		width: 150px;
		height: 150px;
	}

	.random-spa-album-name {
		font-size: 20px;
	}
}

@media screen and (max-width: 500px) {
	#root {
		flex-direction: column;
	}

	.random-spa-container,
	.random-spa-body {
		width: 100%;
		align-items: center;
		padding: 0;
		position: relative;
	}

	.random-spa-container {
		height: 40%;
		padding-top: 20px;
	}

	.random-spa-body {
		height: 60%;
		padding: 40px 0 50px;
	}

	.random-spa-header {
		width: calc(100% - 40px);
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.random-spa-flex {
		flex: 0;
	}

	.random-spa-title {
		font-size: 60px;
	}

	.random-spa-subtitle {
		display: none;
	}

	.random-spa-header-button {
		font-size: 20px;
		padding: 10px 15px;
	}

	.random-spa-input {
		padding: 15px 20px;
		font-size: 16px;
	}

	.input-control-clear {
		font-size: 23px;
		top: calc(50% - 7px);
		right: 17px;
	}

	.input-control-search {
		font-size: 25px;
		margin: 0 0 0 10px;
		top: calc(50% - 9px);
		right: 15px;
	}

	.input-container {
		width: 95%;
		margin: 0;
		margin-bottom: 25px;
	}

	.random-spa-loading-icon {
		font-size: 65px;
		font-weight: 500;
	}

	.random-spa-loading-text {
		display: none;
	}

	.random-spa-result-recents {
		width: 85%;
		font-size: 15px;
	}

	.random-spa-artists-container {
		width: 80%;
		max-height: 80%;
		justify-content: flex-start;
		align-items: flex-start;
		overflow-y: scroll;
		padding-bottom: 25%;
	}

	.random-spa-artists-container:after {
		content: '';
		position: absolute;
		bottom: 0;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #7ed857 35%);
		height: 25%;
		width: 100%;
	}

	.random-spa-artist-container {
		padding: 5px 30px;
		opacity: 1 !important;
		filter: grayscale(0%) !important;
	}

	.random-spa-artist-container:first-child {
		border: none;
	}

	.random-spa-artist-container img {
		width: 65px;
		height: 65px;
	}

	.default-no-image {
		width: 65px;
		height: 65px;
		font-size: 35px;
	}

	.random-spa-artist-name {
		font-size: 20px;
	}

	.random-spa-album {
		width: 200px;
		height: 200px;
	}

	.random-spa-results {
		gap: 15px;
	}

	.random-spa-results-info {
		max-width: 85vw;
		gap: 5px;
	}

	.random-spa-results-track {
		font-size: 20px;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.random-spa-results-album {
		font-size: 16px;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.random-spa-results-artist {
		font-size: 13px;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.random-spa-buttons-container {
		gap: 15px;
	}

	.random-spa-button {
		padding: 10px 15px;
	}

	.random-spa-button .icon {
		font-size: 20px;
	}

	.random-spa-button-text {
		margin-top: 0px;
		font-size: 15px;
		font-weight: 500;
	}

	.random-spa-result-name {
		font-size: 13px;
		padding: 7px 15px;
	}

	.random-spa-feedback {
		background-color: rgba(0, 0, 0, 0.9);
		width: fit-content;
		border-radius: 20px;
		bottom: 25px;
	}

	.random-spa-feedback-text {
		font-size: 16px;
	}

	.random-spa-feedback-text,
	.random-spa-feedback .icon {
		color: #fff;
		font-size: 20px;
	}

	.random-spa-feedback .icon {
		font-size: 18px;
		font-weight: bold;
	}

	.random-spa-nav {
		left: initial;
		bottom: initial;
		top: 20px;
		width: 100%;
		justify-content: center;
	}

	.random-spa-nav div {
		font-size: 13px;
	}

	.no-main {
		width: 100% !important;
		width: initial;
		padding-right: 0;
		padding-top: 5%;
		height: 95%;
	}

	.random-spa-block-text {
		font-size: 20px;
	}

	.random-spa-logo {
		top: 10px;
		left: 20px;
		z-index: 1000 !important;
		font-size: 30px;
	}

	.return-button {
		font-size: 30px;
		left: 15px;
		top: 10px;
	}

	.random-spa-albums-container {
		row-gap: 10px;
		padding: 0;
		margin: 0;
	}

	.random-spa-albums-container::-webkit-scrollbar {
		width: 3px !important;
		background: transparent !important;
	}

	.random-spa-albums-container:after {
		content: '';
		position: absolute;
		bottom: 0;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #7ed857 35%);
		height: 25%;
		width: 100%;
	}

	.random-spa-album-container {
		width: 90px;
		opacity: 1;
	}

	.random-spa-album-container img {
		width: 80px;
		height: 80px;
	}

	.random-spa-album-name {
		font-size: 13px;
		overflow: hidden;
		display: -webkit-box;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}